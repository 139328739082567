<template>
    <div class="bottom-info flex_column_center_center" :style="$route.path=='/homepage/index'?'min-width: auto;':'min-width: 1110px;'">
        <div  class="bottom-info-div flex_around">
            <p >主办单位：南京市鼓楼区教育局</p>
            <p >备案号:苏ICP备321456号</p>
            <p>技术支持:腾权信息科技</p>
        </div>
        <!-- <div  class="bottom-info-div flex_center">
            <p >技术支持：江苏腾权信息科技有限公司</p>
            <p>联系电话：025-83759211</p>
        </div> -->
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {}
    },
    mounted(){

        
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.bottom-info {
    // position: absolute;
    // bottom: 0;
    width: 100%;
    height: 148px;
    background: #2B2C33;
    min-width: 1110px;
    /* @include center; */
    // .bottom-info-div:first-child{
    //     margin-bottom: 20px;
    // }
    .bottom-info-div {
        font-size: 14px;
font-weight: 400;
color: #CACACA;
line-height: 20px;
p{margin-right: 100px;}
p:last-child{margin-right: 0;}
// width: 50%;
// max-width: 1128px;
// p:nth-child(1){
// border-right: 1px solid #94959C;
// padding-right: 16px;
// margin-right: 16px;
// width: 300px;
//     text-align: right;
// }
// p:nth-child(2){
//     width: 300px;
//     text-align: left;
// }
    }

}
</style>
